import * as React from 'react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { Text } from '@audi/audi-ui-react';

interface ConsumptionAndEmissionsProps {
  identifiers: string[];
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
  onCaeReady?: () => void;
}

interface CaeData {
  consumption: string | React.ReactNode[];
  emissions: string | React.ReactNode[];
  emissionClass: string | React.ReactNode[];
  dischargedCO2Class?: string | React.ReactNode[];
  dischargedConsumption?: string | React.ReactNode[];
}

export const ConsumptionAndEmissions = ({
  identifiers,
  vueFormatterService,
  localeService,
  onCaeReady,
}: ConsumptionAndEmissionsProps): React.ReactElement | null => {
  const [caeData, setCaeData] = React.useState<CaeData[]>();

  React.useEffect(() => {
    if (vueFormatterService && localeService)
      (async () => {
        const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
          identifiers,
          vueFormatterService,
          localeService,
        );

        const _caeData: CaeData[] = consumptionsAndEmissionsValues
          .map((cae): CaeData | null => {
            // Check if any of the required fields are defined
            if (
              cae &&
              (cae?.formattedConsumption ||
                cae?.formattedEmission ||
                cae?.formattedCo2Class ||
                cae?.formattedDischargedConsumption ||
                cae?.formattedDischargedCo2Class)
            ) {
              return {
                consumption: cae.formattedConsumption
                  ? renderTextWithFootnotesReferencesV2(
                      cleanupEmissionConsumptionMarkup(cae.formattedConsumption || ''),
                    )
                  : '',
                emissions: cae.formattedEmission
                  ? renderTextWithFootnotesReferencesV2(
                      cleanupEmissionConsumptionMarkup(cae.formattedEmission || ''),
                    )
                  : '',
                emissionClass: cae.formattedCo2Class
                  ? renderTextWithFootnotesReferencesV2(
                      cleanupEmissionConsumptionMarkup(cae.formattedCo2Class || ''),
                    )
                  : '',
                dischargedConsumption: cae.formattedDischargedConsumption
                  ? renderTextWithFootnotesReferencesV2(
                      cleanupEmissionConsumptionMarkup(cae.formattedDischargedConsumption),
                    )
                  : '',
                dischargedCO2Class: cae.formattedDischargedCo2Class
                  ? renderTextWithFootnotesReferencesV2(
                      cleanupEmissionConsumptionMarkup(cae.formattedDischargedCo2Class),
                    )
                  : '',
              };
            }
            return null;
          })
          .filter((cae): cae is CaeData => cae !== null);

        if (_caeData.length) setCaeData(_caeData);
      })();
  }, []);

  React.useEffect(() => {
    if (caeData?.length) onCaeReady?.();
  }, [caeData?.length]);

  if (!caeData?.length) return null;

  return (
    <>
      {caeData.map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={`c${i}`} variant="copy2" spaceStackEnd="xs">
          {x.consumption}; {x.emissions}
          {x.emissionClass ? '; ' : ''}
          {x.emissionClass ? x.emissionClass : ''}
          {x.dischargedConsumption ? '; ' : ''}
          {x.dischargedConsumption ? x.dischargedConsumption : ''}
          {x.dischargedCO2Class ? `; ` : ''}
          {x.dischargedCO2Class ? x.dischargedCO2Class : ''}
        </Text>
      ))}
    </>
  );
};
