const FALLBACK_BASE = 'https://dam.audi.com';

export const addSearchParams = (url: string, params: Map<string, string>) => {
  try {
    const base = url.startsWith('/') ? FALLBACK_BASE : undefined;
    const urlObject = new URL(url, base);
    params.forEach((value, key) => urlObject.searchParams.set(key, value));
    return urlObject.toString().slice(base?.length ?? 0);
  } catch (_) {
    return url;
  }
};
