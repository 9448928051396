import { Logger } from '@feature-hub/core';

import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

import { isAudiCode, isCarLine } from './utils';

export { isAudiCode, isCarLine };

const getWltpValues = (
  wltpKeys: string[],
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: LocaleServiceV1,
  logger?: Logger,
) => {
  const wltpValuesPromisesKeys: string[] = [];
  const wltpValuesPromises: Promise<ConsumptionsAndEmissions>[] = [];

  wltpKeys.forEach((wltpKey) => {
    if (isAudiCode(wltpKey)) {
      // get VuE based on Audi Code
      wltpValuesPromisesKeys.push(wltpKey);
      wltpValuesPromises.push(
        vueFormatterService.vueForAudiCode(
          localeService.countryCode,
          localeService.language,
          wltpKey,
        ),
      );
    } else if (isCarLine(wltpKey)) {
      // get VuE based on Carline ID
      wltpValuesPromisesKeys.push(wltpKey);
      wltpValuesPromises.push(
        vueFormatterService.vueRangeForCarline(
          localeService.countryCode,
          localeService.language,
          wltpKey,
        ),
      );
    } else {
      logger?.error(`Given WltpKey "${wltpKey}" is not a CarLine or an AudiCode!`);
    }
  });

  return { wltpValuesPromises, wltpValuesPromisesKeys };
};

/**
 * Returns a Promise that resolves the given wltpKeys with consumption and emission values.
 * Ignores eventual errors with the service but will log them if `logger` is provided.
 * @param {string[]} wltpKeys - an array of audi codes and/or carline IDs
 * @param vueFormatterService
 * @param localeService
 * @param logger - optional, but recommended
 */
/**
 * @deprecated since version 5.2.0 - use getConsumptionsAndEmissionsWithIdentifier instead
 */
/* istanbul ignore next */
export const getConsumptionsAndEmissions = async (
  wltpKeys: string[],
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: LocaleServiceV1,
  logger?: Logger,
): Promise<ConsumptionsAndEmissions[]> => {
  const wltpValues: ConsumptionsAndEmissions[] = [];

  const { wltpValuesPromises, wltpValuesPromisesKeys } = getWltpValues(
    wltpKeys,
    vueFormatterService,
    localeService,
    logger,
  );

  await Promise.allSettled(wltpValuesPromises).then((results) =>
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        wltpValues.push(result.value);
      } else {
        let errorMessage = '';
        if (isCarLine(wltpValuesPromisesKeys[index])) {
          errorMessage = `Cannot get consumption and emission values for Audi Code "${wltpValuesPromisesKeys[index]}"`;
        } else {
          errorMessage = `Cannot get consumption and emission values for CarLine "${wltpValuesPromisesKeys[index]}"`;
        }
        logger?.error(errorMessage, result.reason);
      }
    }),
  );

  return wltpValues;
};

/**
 * Returns a Promise that resolves to a record of the given wltpKeys and their matching consumption and emission values.
 * Ignores eventual errors with the service but will log them if `logger` is provided.
 * @param {string[]} wltpKeys - an array of audi codes and/or carline IDs
 * @param vueFormatterService
 * @param localeService
 * @param logger - optional, but recommended
 */

export const getConsumptionsAndEmissionsWithIdentifiers = async (
  wltpKeys: string[],
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: LocaleServiceV1,
  logger?: Logger,
): Promise<Record<string, ConsumptionsAndEmissions>> => {
  const wltpValues: Record<string, ConsumptionsAndEmissions> = {};

  const { wltpValuesPromises, wltpValuesPromisesKeys } = getWltpValues(
    wltpKeys,
    vueFormatterService,
    localeService,
    logger,
  );

  await Promise.allSettled(wltpValuesPromises).then((results) =>
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        wltpValues[wltpValuesPromisesKeys[index]] = result.value;
      } else {
        let errorMessage = '';
        if (isCarLine(wltpValuesPromisesKeys[index])) {
          errorMessage = `Cannot get consumption and emission values for Audi Code "${wltpValuesPromisesKeys[index]}"`;
        } else {
          errorMessage = `Cannot get consumption and emission values for CarLine "${wltpValuesPromisesKeys[index]}"`;
        }
        logger?.error(errorMessage, result.reason);
      }
    }),
  );
  return wltpValues;
};
