import React, {
  createContext,
  DispatchWithoutAction,
  EffectCallback,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { Logger } from '@feature-hub/core';

const DELAY_IN_MILLISECONDS = 1000;

export const useInViewObservers = (
  dispatch: DispatchWithoutAction,
): [(target: Element) => void, () => void] => {
  const useObserver = (
    shouldTrigger: (intersectionRatio: number) => boolean,
    options: IntersectionObserverInit,
  ): [(target: Element) => void, () => void] => {
    let timeoutId: number | undefined;
    const clearTimeout = () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
    const createTimeoutHandler = () => {
      return (enableTimeout: boolean) => {
        if (enableTimeout) {
          timeoutId = window.setTimeout(() => {
            dispatch();
          }, DELAY_IN_MILLISECONDS);
        } else {
          clearTimeout();
        }
      };
    };

    const createCallback =
      (shouldTriggerCb: (intersectionRatio: number) => boolean) =>
      (entries: IntersectionObserverEntry[]) => {
        const handleTimeout = createTimeoutHandler();
        entries.forEach(({ intersectionRatio }) => {
          handleTimeout(shouldTriggerCb(intersectionRatio));
        });
      };

    let observer: IntersectionObserver;
    const observe = (target: Element) => {
      if (!observer) {
        if (typeof IntersectionObserver === 'undefined') return;

        observer = new IntersectionObserver(createCallback(shouldTrigger), options);
      }

      observer.observe(target);
    };
    const disconnect = () => {
      clearTimeout();
      observer?.disconnect();
    };
    return [observe, disconnect];
  };

  const [observe70, disconnect70] = useObserver((intersectionRatio) => intersectionRatio > 0, {
    rootMargin: '0% 0% -30% 0%',
    threshold: 0,
  });

  const [observe100, disconnect100] = useObserver((intersectionRatio) => intersectionRatio === 1, {
    threshold: 1,
  });

  const observe = (target: Element) => {
    observe70(target);
    observe100(target);
  };
  const disconnect = () => {
    disconnect70();
    disconnect100();
  };
  return [observe, disconnect];
};

interface InViewContextState {
  readonly inView?: boolean;
}
const InViewContext = createContext<InViewContextState>({
  inView: undefined,
});

export type InViewContextProviderProps = PropsWithChildren<{
  readonly logger?: Logger;
}>;

export const InViewContextProvider: FC<InViewContextProviderProps> = ({ logger, children }) => {
  const debug = logger?.debug.bind(undefined, 'InViewContext:');
  const inViewRef = useRef<HTMLDivElement>(null);
  const [inView, setInView] = useReducer(() => true, false);
  const [observe, disconnect] = useInViewObservers(setInView);

  useEffect(() => {
    if (inViewRef.current) {
      debug?.('Connect observer instances.');
      observe(inViewRef.current);
    }

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (inView) {
      debug?.('Component is in view. Disconnecting observer instances.');
      disconnect();
    }
  }, [inView]);

  return (
    <InViewContext.Provider value={{ inView }}>
      <div ref={inViewRef}>{children}</div>
    </InViewContext.Provider>
  );
};
InViewContext.displayName = 'InViewContextProvider';

export const useInViewEffect = (effect: EffectCallback): void => {
  const { inView } = useContext(InViewContext);

  if (inView === undefined) {
    throw new Error(`Unable to track if component is in view. Did you forget to add 'InViewContextProvider'? You need to wrap your components with this provider to use useInViewEffect:
<InViewContextProvider>
    <YOUR_COMPONENT />
</InViewContextProvider>`);
  }

  useEffect(() => {
    if (inView) {
      effect();
    }
  }, [inView]);
};
