import React, { useState, useEffect, useContext, createContext, PropsWithChildren } from 'react';
import { ContentServiceV1 } from '@oneaudi/content-service';

export interface ContentContextState {
  readonly content: unknown;
}

const ContentContext = createContext<ContentContextState>({
  content: undefined,
});

export interface ContentContextProviderProps {
  readonly contentService: ContentServiceV1;
}

export function ContentContextProvider({
  children,
  contentService,
}: PropsWithChildren<ContentContextProviderProps>) {
  const [content, setContent] = useState(contentService.getContent());

  useEffect(() => {
    const listener = () => {
      setContent(contentService.getContent());
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService]);

  return <ContentContext.Provider value={{ content }}>{children}</ContentContext.Provider>;
}

export function useContent<TContent>(): TContent | undefined {
  // throw error if not wrapped in ContentContextProvider
  const { content } = useContext(ContentContext);
  return content !== undefined ? (content as TContent) : undefined;
}
