import styled from 'styled-components';

export const StyledUniversalBreakpointWrapper = styled.div<{
  mediaQuery: string;
  cssDisplay: string;
}>`
  display: none;

  @media ${({ mediaQuery }) => mediaQuery} {
    display: ${({ cssDisplay }) => cssDisplay};
  }
`;
