/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  PAGE_MARGIN_L,
  PAGE_MARGIN_M,
  PAGE_MARGIN_S,
  PAGE_MARGIN_XL,
  PAGE_MARGIN_XS,
  PAGE_MARGIN_XXL,
  SPACING_XS_XS,
} from '@audi/audi-ui-design-tokens';
import {
  Button,
  SpacingProps,
  SpacingShorthandList,
  Text,
  Theme,
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
  useSpacing,
} from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2, useContent } from '@oneaudi/feature-app-utils';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import * as React from 'react';
import styled from 'styled-components';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { EditorJsonContent, FalconContent, mapContent } from '../contentMapping/contentMapping';

import { APP_ID } from '../../environment';
import type {
  EditorialTeaserComponent,
  EditorialTeaserContent,
  EditorialTeaserServices,
  ThemeColor,
} from '../../types';
import { TrackingClickEvent, useTracking } from '../tracking';
import { convertRichTextWithFootnotes, isDarkTheme, isDebugMode } from '../utils';
import { ImageFull, ImageHalf, ImageThird, ImageThirdFirst } from './Images';
import { LegalInfo } from './LegalInfo';
import { parseAssetValue } from '../../utils/parseAssetValue';

const TeaserContainer = styled.div<{
  spacing: SpacingShorthandList;
  numberOfComponents: number;
}>`
  box-sizing: border-box;
  display: grid;
  &:first-child {
    margin-block-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxxl})`};
  }
  &:last-child {
    margin-block-end: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.xxxl})`};
  }
  margin-inline-start: ${PAGE_MARGIN_XS}px;
  margin-inline-end: ${PAGE_MARGIN_XS}px;

  @media screen and (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ numberOfComponents }) => {
      if (numberOfComponents === 1) {
        return `
            grid-template-areas:
            "a a"
            "b c";
          grid-gap: ${SPACING_XS_XS}px;
          grid-auto-columns: 1fr;
            `;
      }
      return '';
    }}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.s}px) {
    margin-inline-start: ${PAGE_MARGIN_S}px;
    margin-inline-end: ${PAGE_MARGIN_S}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    margin-inline-start: ${PAGE_MARGIN_M}px;
    margin-inline-end: ${PAGE_MARGIN_M}px;
    ${({ numberOfComponents }) => {
      if (numberOfComponents === 1 || numberOfComponents === 3) {
        return `
            grid-template-areas:
            "a a"
            "b c";
          grid-gap: ${SPACING_XS_XS}px;
          grid-auto-columns: 1fr;
            `;
      }
      if (numberOfComponents === 2) {
        return `
            grid-template-columns: 1fr 1fr;
            grid-gap: ${SPACING_XS_XS}px;
            `;
      }
      return '';
    }}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    margin-inline-start: ${PAGE_MARGIN_L}px;
    margin-inline-end: ${PAGE_MARGIN_L}px;
    ${({ numberOfComponents }) => {
      if (numberOfComponents === 1) {
        return `
            grid-template-areas:
            "a a"
            "b c";
          grid-gap: ${SPACING_XS_XS}px;
          grid-auto-columns: 1fr;
            `;
      }
      if (numberOfComponents === 2) {
        return `
            grid-template-columns: 1fr 1fr;
            grid-gap: ${SPACING_XS_XS}px;
            `;
      }

      if (numberOfComponents === 3) {
        return `
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: ${SPACING_XS_XS}px;
            `;
      }
      return '';
    }}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    margin-inline-start: ${PAGE_MARGIN_XL}px;
    margin-inline-end: ${PAGE_MARGIN_XL}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
    margin-inline-start: ${PAGE_MARGIN_XXL}px;
    margin-inline-end: ${PAGE_MARGIN_XXL}px;
  }
`;

const StyledComponent = styled.div<{
  index: number;
  gridArea: string;
  numberOfComponents: number;
}>`
  ${({ numberOfComponents, gridArea }) => {
    if (numberOfComponents === 1) {
      return `
        flex-direction: column;
        grid-area: ${gridArea};
      `;
    }
    return '';
  }}

  .component__inner {
    display: flex;
    height: auto;
    flex-direction: column;
    height: 100%;
  }

  /* Important: Styled for UEReference component, remove if there is a another
  way to styled UEReference components with StyledComponents adding classNames, etc. */
  & > div:first-child {
    display: flex;
    height: auto;
    flex-direction: column;
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    ${({ theme, index }: { theme: Theme; index: number }) =>
      index > 0
        ? `
      margin-block-start: var(${theme.responsive.spacing.xl});
`
        : ''}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.l - 1}px) {
    ${({ numberOfComponents, gridArea }) => {
      if (numberOfComponents === 3) {
        return `
        flex-direction: column;
        grid-area: ${gridArea};
            `;
      }
      return '';
    }}
  }

  a {
    text-decoration: none !important;
  }
`;

const Media = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
`;

const TextArea = styled.div`
  height: 100%;
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_BLACK : COLOR_BASE_BRAND_WHITE};

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme)
        ? `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.l});
        padding-block-end: var(${theme.responsive.spacing.m});
        padding-inline-start: var(${theme.responsive.spacing.l});
  `
        : `
        padding-block-start: var(${theme.responsive.spacing.m});
        `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme)
        ? `
        padding-block-start: var(${theme.responsive.spacing.l});
        padding-inline-end: var(${theme.responsive.spacing.xl});
        padding-block-end: var(${theme.responsive.spacing.l});
        padding-inline-start: var(${theme.responsive.spacing.xl});
  `
        : `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.xl});
        `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme)
        ? `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.l});
        padding-block-end: var(${theme.responsive.spacing.m});
        padding-inline-start: var(${theme.responsive.spacing.l});
  `
        : `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.xl});
        `}
  }
`;

const ButtonContainer = styled.div`
  margin-block-start: ${({ theme }: { theme: Theme }) =>
    `calc(-1 * var(${theme.responsive?.spacing.m}))`};

  &:first-child {
    margin-block-start: 0;
  }

  display: flex;
  justify-content: start;
  align-items: center;
  padding: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.m}) 0`};
`;

const TEXT_SPACING_LIGHT: SpacingProps = {
  spaceStackStart: 'm',
  spaceStackEnd: 's',
};

const TEXT_SPACING_DARK: SpacingProps = {
  spaceStackStart: 'l',
  spaceStackEnd: 'l',
};

const Component = ({
  themeColor,
  content: {
    imageAsset,
    image,
    heading,
    headingTag,
    text,
    links: unfilteredLinks,
    consumptionAndEmissions,
    disclaimers,
    originalIndex,
  },
  index,
  numberOfComponents,
  sendClickEvent,
  vueFormatterService,
  localeService,
}: {
  themeColor?: ThemeColor;
  content: ChunkedEditorialTeaserComponent;
  index: number;
  numberOfComponents: number;
  sendClickEvent?: (event: TrackingClickEvent, componentIndex: number) => void;
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
}): React.ReactElement => {
  const hasLegalInfo = !!consumptionAndEmissions?.length || !!disclaimers?.length;
  // eslint-disable-next-line no-nested-ternary
  const gridArea = index === 0 ? 'a' : index === 1 ? 'b' : 'c';

  // remove links without label
  const links =
    unfilteredLinks && unfilteredLinks.length ? unfilteredLinks.filter((link) => link.label) : [];

  const [isCSR, setIsCSR] = React.useState(false);

  React.useEffect(() => {
    setIsCSR(true);
  }, []);

  const handleTextLinkClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    targetURL: string,
    linkLabel: string,
  ) => {
    e.stopPropagation();
    if (sendClickEvent)
      sendClickEvent(
        {
          name: 'click on teaser',
          elementName: 'text link',
          value: heading,
          pos: `${originalIndex + 1}`,
          targetURL,
          label: linkLabel,
        },
        originalIndex,
      );
  };

  const renderImage = () => {
    const assetSrc = imageAsset?.src ? parseAssetValue(imageAsset.src.assetValue).src : image?.src;
    const imageSrc = assetSrc || '';

    switch (true) {
      case numberOfComponents === 3 && index === 0:
        return <ImageThirdFirst src={imageSrc} alt={image?.alt} />;
      case numberOfComponents === 3:
        return <ImageThird src={imageSrc} alt={image?.alt} />;
      case numberOfComponents === 2:
        return <ImageHalf src={imageSrc} alt={image?.alt} />;
      default:
        return <ImageFull src={imageSrc} alt={image?.alt} />;
    }
  };

  const body = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div style={{ height: '100%' }} className="component__inner">
      <Media>
        <div>{renderImage()}</div>
      </Media>
      <TextArea>
        {heading && (
          <UeElement type="text" property="heading" label="Headline">
            {(ueProps) => (
              <Text variant="order3" as={headingTag || 'h3'} {...ueProps}>
                {isCSR
                  ? convertRichTextWithFootnotes(heading)
                  : renderTextWithFootnotesReferencesV2(heading)}
              </Text>
            )}
          </UeElement>
        )}

        {text && (
          <UeElement type="richtext" property="text" label="Copy">
            {(ueProps) => (
              <Text
                {...(themeColor === 'dark' ? TEXT_SPACING_DARK : TEXT_SPACING_LIGHT)}
                {...ueProps}
              >
                {isCSR
                  ? convertRichTextWithFootnotes(text)
                  : renderTextWithFootnotesReferencesV2(text)}
              </Text>
            )}
          </UeElement>
        )}

        {Boolean(links && links.length) && (
          <div>
            <UeReference propertyPath={`components.${index}`} label="CTAs">
              {links &&
                links.map((link, i) => {
                  return (
                    <ButtonContainer>
                      <Button
                        variant="text"
                        key={link.label}
                        aria-label={link.ariaLabel || ''}
                        newWindow={link.openInNewTab}
                        href={link.url}
                        onClick={(e) => handleTextLinkClick(e, link.url, link.label)}
                      >
                        <UeElement type="text" property={`links_${i}_label`} label={`link ${i} `}>
                          {link.label}
                        </UeElement>
                      </Button>
                    </ButtonContainer>
                  );
                })}
            </UeReference>
          </div>
        )}

        {hasLegalInfo && (
          <LegalInfoContainer>
            <LegalInfo
              consumptionAndEmissions={consumptionAndEmissions || []}
              disclaimers={disclaimers}
              vueFormatterService={vueFormatterService}
              localeService={localeService}
            />
          </LegalInfoContainer>
        )}
      </TextArea>
    </div>
  );

  return (
    <StyledComponent
      gridArea={gridArea}
      numberOfComponents={numberOfComponents}
      index={originalIndex}
    >
      <UeReference propertyPath={`components.${originalIndex}`} as="div">
        {body}
      </UeReference>
    </StyledComponent>
  );
};

const LegalInfoContainer = styled.div``;

export const EditorialTeaser = ({
  components,
  themeColor = 'light',
  vueFormatterService,
  localeService,
  trackingService,
  chunkedComponentsSSR,
}: EditorialTeaserContent &
  EditorialTeaserServices & {
    chunkedComponentsSSR: any;
  }): React.ReactElement => {
  const chunkedComponents: any = chunkedComponentsSSR || sliceComponentsIntoChunks(components, 3);
  const ref = React.useRef(null);
  const teaserContainerSpacing = useSpacing({ spaceStackStart: 'xxxl', spaceStackEnd: 'xxxl' });
  let relatedElements: any;
  if (typeof window !== 'undefined') {
    relatedElements = React.useRef(
      components.map((x, i) => ({
        elementName: 'teaser',
        elementValue: `${i} - ${
          x.heading || `${x.text ? `${x.text?.slice(0, 10)}...` : '(no text)'}`
        }`,
      })),
    );
  }
  const {
    registerImpressionTracking,
    sendReadyEvent,
    sendClickEvent: _sendClickEvent,
  } = useTracking(trackingService);
  const sendClickEvent = React.useCallback(
    (event: TrackingClickEvent, componentIndex: number) => {
      _sendClickEvent({
        ...event,
        relatedElements: [relatedElements.current[componentIndex]],
      });
    },
    [_sendClickEvent],
  );

  React.useEffect(() => sendReadyEvent(), []);

  React.useEffect(() => {
    if (ref.current)
      registerImpressionTracking(ref.current, {
        relatedElements: relatedElements.current,
      });
  }, [ref]);

  return (
    <ThemeProvider theme={themeColor === 'dark' ? audiDarkTheme : audiLightTheme}>
      <div ref={ref} data-testid="editorial-teaser">
        <UeContainer propertyPath="components">
          {chunkedComponents?.map((chunk: ChunkedEditorialTeaserComponent[]) => (
            <TeaserContainer spacing={teaserContainerSpacing} numberOfComponents={chunk.length}>
              {chunk?.map((chunkedComponent: ChunkedEditorialTeaserComponent, i: number) => (
                <Component
                  key={[chunkedComponent.originalIndex, chunkedComponent.heading].join('_')}
                  themeColor={themeColor}
                  index={i}
                  content={chunkedComponent}
                  numberOfComponents={chunk.length}
                  sendClickEvent={sendClickEvent}
                  vueFormatterService={vueFormatterService}
                  localeService={localeService}
                />
              ))}
            </TeaserContainer>
          ))}
        </UeContainer>
      </div>
    </ThemeProvider>
  );
};

export const EditorialTeaserContentLoader = ({
  initialContent,
  localeService,
  vueFormatterService,
  ssrContent,
  chunkedComponents,
  trackingService,
}: {
  initialContent?: EditorialTeaserContent;
  ssrContent: EditorialTeaserContent;
  chunkedComponents: ChunkedEditorialTeaserComponent[];
  trackingService?: TrackingServiceV2;
} & EditorialTeaserServices): React.ReactElement | null => {
  let content: EditorJsonContent;
  if (!ssrContent) {
    const rawContent = useContent<EditorJsonContent | FalconContent>() || initialContent;
    // eslint-disable-next-line no-console
    content = mapContent(rawContent as EditorJsonContent | FalconContent);
  } else {
    content = ssrContent;
  }

  if (isDebugMode())
    // eslint-disable-next-line no-console
    console.debug(`${APP_ID} ->`, {
      content,
    });

  if (!content) return null;

  return (
    <EditorialTeaser
      {...content}
      localeService={localeService}
      trackingService={trackingService}
      vueFormatterService={vueFormatterService}
      chunkedComponentsSSR={chunkedComponents}
    />
  );
};

interface ChunkedEditorialTeaserComponent extends EditorialTeaserComponent {
  // additional prop for remembering the index from the original
  // array of components before chunking it. Only used for
  // tracking purposes.
  originalIndex: number;
}

/**
 * Put teaser components data in a chunked array so that
 * more than 3 columns can be rendered
 */
function sliceComponentsIntoChunks(
  components: EditorialTeaserComponent[],
  chunkSize: number,
): ChunkedEditorialTeaserComponent[][] {
  const chunkedComponents: ChunkedEditorialTeaserComponent[][] = [];
  let chunk: ChunkedEditorialTeaserComponent[] = [];

  if (typeof window !== 'undefined') {
    components.forEach((component, componentIndex) => {
      chunk.push({ ...component, originalIndex: componentIndex });

      if (chunk.length >= chunkSize) {
        chunkedComponents.push(chunk);
        chunk = [];
      }
    });

    if (chunk.length) {
      chunkedComponents.push(chunk);
    }
  }

  return chunkedComponents;
}
