import { UeElement } from '@oneaudi/falcon-tools';
import { addWidthParameter } from '@oneaudi/feature-app-utils';
import * as React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const toSrcSetPartial = (src: string, width: number) =>
  `${addWidthParameter(src, width)} ${width}w`;

const toSrcSet = (src: string, widths: number[]) =>
  widths.map(toSrcSetPartial.bind(undefined, src)).join(',');

type MediaQuerySize = [number, number];

const toSizePartial = ([minWidth, width]: MediaQuerySize) => `(min-width:${minWidth}px)${width}px`;

const toSizes = (sizes: MediaQuerySize[], defaultWidth: number) =>
  `${sizes.map(toSizePartial).join(',')},${defaultWidth}px`;

interface ImageElementProps extends ImageProps {
  readonly srcSet: string;
  readonly sizes: string;
}

const ImageElement = React.memo(function ImageElement({
  src,
  srcSet,
  sizes,
  alt,
}: ImageElementProps) {
  return (
    <UeElement type="media" property="imageAsset_src" label="image">
      {(ueElemProps) => (
        <StyledImage
          src={addWidthParameter(src, 342)}
          srcSet={srcSet}
          sizes={sizes}
          alt={alt}
          width="1920"
          height="1080"
          loading="lazy"
          {...ueElemProps}
        />
      )}
    </UeElement>
  );
});

export interface ImageProps {
  readonly src: string;
  readonly alt?: string;
}

export const ImageThirdFirst = React.memo(function ImageThirdFirst({ src, alt }: ImageProps) {
  return (
    <ImageElement
      src={src}
      srcSet={toSrcSet(src, [342, 372, 435, 571, 684, 711, 744, 870, 943, 1142, 1422, 1886])}
      sizes={toSizes(
        [
          [1514, 571],
          [1163, 435],
          [1024, 342],
          [768, 943],
          [429, 711],
          [399, 372],
        ],
        342,
      )}
      alt={alt}
    />
  );
});

export const ImageThird = React.memo(function ImageThirSecond({ src, alt }: ImageProps) {
  return (
    <ImageElement
      src={src}
      srcSet={toSrcSet(src, [342, 372, 435, 468, 571, 684, 711, 744, 870, 936, 1142, 1422])}
      sizes={toSizes(
        [
          [1514, 571],
          [1163, 435],
          [1024, 342],
          [768, 468],
          [429, 711],
          [399, 372],
        ],
        342,
      )}
      alt={alt}
    />
  );
});

export const ImageHalf = React.memo(function ImageHalf({ src, alt }: ImageProps) {
  return (
    <ImageElement
      src={src}
      srcSet={toSrcSet(src, [342, 372, 468, 656, 684, 711, 744, 860, 936, 1312, 1422, 1720])}
      sizes={toSizes(
        [
          [1513, 860],
          [1065, 656],
          [768, 468],
          [429, 711],
          [399, 372],
        ],
        342,
      )}
      alt={alt}
    />
  );
});

export const ImageFull = React.memo(function ImageFull({ src, alt }: ImageProps) {
  return (
    <ImageElement
      src={src}
      srcSet={toSrcSet(src, [342, 372, 684, 711, 744, 943, 1319, 1422, 1728, 1886, 2638, 3456])}
      sizes={toSizes(
        [
          [1512, 1728],
          [1064, 1319],
          [792, 943],
          [429, 711],
          [399, 372],
        ],
        342,
      )}
      alt={alt}
    />
  );
});
