import type { NodeEnvironment } from './types';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      NODE_ENV: NodeEnvironment;
    }
  }
}

export const APP_ID: string =
  (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
export const APP_VERSION: string =
  (typeof __FEATURE_APP_VERSION__ === 'string' && __FEATURE_APP_VERSION__) || '';
export const NODE_ENVIRONMENT = process.env.NODE_ENV;
export const STAGE = process.env.STAGE as 'dev' | 'prod';
