import * as React from 'react';
import styled from 'styled-components';
import { Text, Theme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';

const LegalInfoContainer = styled.div`
  margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l})`};
`;

interface Props {
  consumptionAndEmissions: string[];
  disclaimers?: string[];
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
  onCaeReady?: () => void;
}

export const LegalInfo: React.FC<Props> = ({
  disclaimers,
  consumptionAndEmissions,
  vueFormatterService,
  localeService,
  onCaeReady,
}) => (
  <LegalInfoContainer>
    {consumptionAndEmissions && (
      <ConsumptionAndEmissions
        identifiers={consumptionAndEmissions}
        localeService={localeService}
        vueFormatterService={vueFormatterService}
        onCaeReady={onCaeReady}
      />
    )}
    {disclaimers?.map((x, i) => (
      <Text key={[i, x].join('_')} variant="copy2" spaceStackEnd="xs">
        {renderTextWithFootnotesReferencesV2(x)}
      </Text>
    ))}
  </LegalInfoContainer>
);
