/**
 * Removes markup from EC string
 * @param ecString
 *
 * */
export function cleanupEmissionConsumptionMarkup(ecString: string): string {
  return ecString
    .replace(/{{(?!footnote\.reference)([^}]+)}}/g, (_, match) => `{${match}}`)
    .replace(/<sub[^>]*>2<\/sub>/g, '₂')
    .replace(/<\/?sup[^>]*>/g, '')
    .replace(/&#x2[fF];/g, '/');
}
